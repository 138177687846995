import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import BgImage from "../components/bgImage"
import FiftyFifty from '../components/fiftyfifty'

const IndexPage = (props) => {
  return(
    <section>
      <SEO title="Steel windows" description='Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings' />
      <BgImage 
        image={props.data.mainImage.childImageSharp.fluid}
        text="Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings. Windows and doors crafted from our profiles are often the most prominent feature in prestigious estates and custom homes."
        title="Steel Windows"
        subtitle="Minimalistic modern design"
      ></BgImage>
      <FiftyFifty  data={props.data.allBrands} type="brands" />     
    </section>
  )
}
    export const query  = graphql`
{
  mainImage: file(relativePath: {regex: "/steelWindows/"}) {
    childImageSharp {
      fluid(maxWidth: 1980) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allBrands {
     edges {
       node {
         brand
         localImage {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
         content
         id
       }
     }
   }
}`

export default IndexPage